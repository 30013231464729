import React from 'react';
import { useMining } from '../context/MiningContext';
import './Mining.css';
import '../styles/common.css';

function Mining() {
  const {
    balance = 0,
    miningPower = 0,
    isActive = false,
    energy = 0,
    maxEnergy = 100,
    equipment = 0,
    cycleReward = 0,
    totalMined = 0,
    minersOnline = 0,
    totalMiners = 0,
    miningHistory = [],
    startMining,
    stopMining,
    buyEquipment,
    upgradeEnergy,
    lastMiningAttempt = { success: true, reward: 0 }
  } = useMining();

  return (
    <div className="page">
      <div className="content-block main-stats">
        <div className="balance-section">
          <span className="label">Баланс</span>
          <span className="value highlight">{(balance || 0).toFixed(8)} BUCKS</span>
        </div>
        <div className="stats-grid">
          <div className="stat-item">
            <span className="label">Мощность</span>
            <span className="value">{(miningPower || 0).toFixed(1)} MH/s</span>
          </div>
          <div className="stat-item">
            <span className="label">Энергия</span>
            <span className="value">{energy || 0}/{maxEnergy || 100}⚡</span>
          </div>
          <div className="stat-item">
            <span className="label">Оборудование</span>
            <span className="value">{equipment || 0} шт.</span>
          </div>
        </div>
      </div>

      <div className="content-block mining-stats">
        <div className="last-reward">
          <span className="label">Последняя награда</span>
          <span className={`value ${lastMiningAttempt?.success ? 'success' : 'failed'}`}>
            {lastMiningAttempt?.success ? `+${(cycleReward || 0).toFixed(8)}` : 'Неудачная попытка'}
          </span>
        </div>
      </div>

      <div className="content-block">
        <button
          className={`mining-button ${isActive ? 'active' : ''}`}
          onClick={isActive ? stopMining : startMining}
          disabled={energy <= 0}
        >
          {isActive ? 'Остановить майнинг' : 'Начать майнинг'}
          {energy <= 0 && <span className="warning">Нет энергии</span>}
        </button>
      </div>

      <div className="content-block">
        <div className="action-buttons">
          <button
            className="action-button"
            onClick={() => {
              buyEquipment().catch(error => {
                if (error.message === 'Insufficient stars') {
                  if (window.Telegram?.WebApp) {
                    window.Telegram.WebApp.showAlert('Недостаточно звезд для покупки. Необходимо: 300⭐');
                  }
                }
              });
            }}
          >
            <span className="button-content">
              <span>Оборудование</span>
              <span>300⭐</span>
            </span>
          </button>
          <button
            className="action-button"
            onClick={() => {
              upgradeEnergy().catch(error => {
                if (error.message === 'Insufficient stars') {
                  if (window.Telegram?.WebApp) {
                    window.Telegram.WebApp.showAlert('Недостаточно звезд для улучшения. Необходимо: 300⭐');
                  }
                }
              });
            }}
          >
            <span className="button-content">
              <span>Энергия</span>
              <span>300⭐</span>
            </span>
          </button>
        </div>
      </div>

      <div className="content-block online-stats">
        <div className="info-row">
          <span className="label">Майнеров онлайн</span>
          <span className="value highlight">{minersOnline}</span>
        </div>
        <div className="info-row">
          <span className="label">Всего майнеров</span>
          <span className="value">{totalMiners}</span>
        </div>
      </div>

      <div className="content-block history-block">
        <h3 className="section-header">История добычи</h3>
        <div className="history-list">
          {miningHistory.map((record, index) => (
            <div
              key={record._id || index}
              className={`history-item ${record.success ? 'success' : 'failed'}`}
            >
              <div className="history-header">
                <span className="username">{record.username}</span>
                <span className="timestamp">
                  {new Date(record.timestamp).toLocaleTimeString()}
                </span>
              </div>
              <div className="history-details">
                <span className="power">{record.power.toFixed(1)} MH/s</span>
                <span className={`amount ${record.success ? 'success' : 'failed'}`}>
                  {record.success ? (
                    `+${record.amount.toFixed(8)}`
                  ) : (
                    'Неудачная попытка'
                  )}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Mining;