import React from 'react';
import '../styles/common.css';

function About() {
  return (
    <div className="page">
      <div className="content-block">
        <h3 className="section-header">О проекте</h3>
        <div className="info-text">
          <p>MemBucks - это игра-майнер с элементами экономической стратегии, где вы развиваете свою майнинг-ферму и соревнуетесь с другими игроками.</p>
        </div>
      </div>

      <div className="content-block">
        <h3 className="section-header">Как играть</h3>
        <div className="info-text">
          <div className="info-item">
            <span className="info-number">1</span>
            <span>Майните токены $BUCKS, используя энергию ⚡</span>
          </div>
          <div className="info-item">
            <span className="info-number">2</span>
            <span>Покупайте оборудование для увеличения дохода 🔧</span>
          </div>
          <div className="info-item">
            <span className="info-number">3</span>
            <span>Улучшайте запас энергии для длительного майнинга 🔋</span>
          </div>
          <div className="info-item">
            <span className="info-number">4</span>
            <span>Выполняйте задания для получения бонусов 📋</span>
          </div>
        </div>
      </div>

      <div className="content-block">
        <h3 className="section-header">Механика игры</h3>
        <div className="info-text">
          <div className="mechanics-item">
            <span className="mechanics-icon">⚡</span>
            <span>100 энергии восстанавливается за 3 минуты</span>
          </div>
          <div className="mechanics-item">
            <span className="mechanics-icon">⏱️</span>
            <span>Цикл майнинга: 3-10 секунд</span>
          </div>
          <div className="mechanics-item">
            <span className="mechanics-icon">📈</span>
            <span>Шанс успешного майнинга: 90%</span>
          </div>
          <div className="mechanics-item">
            <span className="mechanics-icon">💰</span>
            <span>Награда зависит от мощности оборудования</span>
          </div>
        </div>
      </div>

      <div className="content-block">
        <h3 className="section-header">Важно знать</h3>
        <div className="info-text">
          <div className="warning-item">
            <span className="warning-icon">⚠️</span>
            <span>Майнинг возможен только с одного устройства</span>
          </div>
          <div className="warning-item">
            <span className="warning-icon">⚠️</span>
            <span>Энергия восстанавливается только при остановленном майнинге</span>
          </div>
          <div className="warning-item">
            <span className="warning-icon">⚠️</span>
            <span>Использование ботов и скриптов запрещено</span>
          </div>
        </div>
      </div>

      <div className="content-block">
        <h3 className="section-header">Поддержка проекта</h3>
        <div className="info-text">
          <div className="support-item">
            <span className="support-icon">⭐</span>
            <span>Вы можете поддержать развитие проекта, купив звезды</span>
          </div>
          <div className="support-item">
            <span className="support-icon">💎</span>
            <span>Все средства идут на поддержку команды разработчиков</span>
          </div>
          <div className="support-item">
            <span className="support-icon">🚀</span>
            <span>Помогите нам сделать игру еще лучше и интереснее</span>
          </div>
          <div className="support-note">
            <span className="warning-icon">ℹ️</span>
            <span>Все покупки за звезды являются добровольными пожертвованиями на развитие проекта</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;