import React, { useEffect, useState } from 'react';
import { useMining } from '../context/MiningContext';
import '../styles/common.css';

function Rating() {
  const { api } = useMining();
  const [topMiners, setTopMiners] = useState([]);
  const [userRank, setUserRank] = useState(null);
  const telegramId = window.Telegram?.WebApp?.initDataUnsafe?.user?.id;

  useEffect(() => {
    const fetchTopMiners = async () => {
      try {
        const miners = await api.getTopMiners();
        setTopMiners(miners);

        // Если пользователя нет в топ-100, получаем его ранг
        if (telegramId && !miners.find(m => m.telegramId === telegramId)) {
          const userRankData = await api.getUserRank(telegramId);
          setUserRank(userRankData);
        }
      } catch (error) {
        console.error('Error fetching miners:', error);
      }
    };

    fetchTopMiners();
  }, [api, telegramId]);

  const formatNumber = (num) => {
    if (num === undefined || num === null) return '0.000000';
    return Number(num).toFixed(6);
  };

  return (
    <div className="page">
      <div className="content-block">
        <h3 className="section-header">Топ майнеров</h3>
        <div className="list-container">
          {topMiners.map((miner, index) => (
            <div
              key={miner._id || index}
              className={`list-item ${index < 10 ? 'top-10' : ''}`}
            >
              <div className="info-row">
                <div className="miner-info">
                  <span className="rank">{index + 1}</span>
                  <span className="username">{miner.username}</span>
                </div>
                <span className="value highlight">{formatNumber(miner.totalMined)} $BUCKS</span>
              </div>
              <div className="info-row">
                <span className="power">{miner.miningPower.toFixed(1)} MH/s</span>
                <span className="equipment">{miner.equipment} шт.</span>
              </div>
            </div>
          ))}
        </div>

        {/* Показываем ранг пользователя, если он не в топ-100 */}
        {userRank && (
          <div className="user-rank">
            <div className="list-item current-user">
              <div className="info-row">
                <div className="miner-info">
                  <span className="rank">{userRank.rank}</span>
                  <span className="username">{userRank.username}</span>
                </div>
                <span className="value highlight">{formatNumber(userRank.totalMined)} $BUCKS</span>
              </div>
              <div className="info-row">
                <span className="power">{userRank.miningPower.toFixed(1)} MH/s</span>
                <span className="equipment">{userRank.equipment} шт.</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Rating;