import React, { createContext, useContext, useState, useEffect, useMemo, useCallback } from 'react';

const MiningContext = createContext();

// В начале файла добавим тестовые данные
const TEST_USER = {
  id: '12345',
  username: 'test_user',
  first_name: 'Test',
  last_name: 'User'
};

// Создадим имитацию Telegram WebApp для локальной разработки
if (!window.Telegram) {
  window.Telegram = {
    WebApp: {
      initData: 'test_init_data',
      initDataUnsafe: {
        user: TEST_USER,
        query_id: '123456789',
        auth_date: Date.now(),
        hash: 'test_hash'
      },
      ready: () => console.log('WebApp ready'),
      expand: () => console.log('WebApp expand'),
      showAlert: (message) => alert(message),
      HapticFeedback: {
        impactOccurred: (style) => console.log('Haptic feedback:', style)
      }
    }
  };
}

export function MiningProvider({ children }) {
  // Состояния
  const [balance, setBalance] = useState(0);
  const [miningPower, setMiningPower] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [energy, setEnergy] = useState(100);
  const [maxEnergy, setMaxEnergy] = useState(100);
  const [equipment, setEquipment] = useState(0);
  const [cycleReward, setCycleReward] = useState(0);
  const [lastCycleTime, setLastCycleTime] = useState(0);
  const [totalMined, setTotalMined] = useState(0);
  const [minersOnline, setMinersOnline] = useState(0);
  const [totalMiners, setTotalMiners] = useState(0);
  const [miningHistory, setMiningHistory] = useState([]);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const [tabId] = useState(Math.random().toString(36).substring(7));
  const [isPurchaseInProgress, setIsPurchaseInProgress] = useState(false);
  const [isUpgradeInProgress, setIsUpgradeInProgress] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isReconnecting, setIsReconnecting] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [lastMiningAttempt, setLastMiningAttempt] = useState({ success: true, reward: 0 });
  const [tasks, setTasks] = useState([]);
  const [startTime] = useState(Date.now());
  const [miningSessionsCount, setMiningSessionsCount] = useState(0);

  // Константы
  const TOTAL_SUPPLY = 100_000_000;
  const TOTAL_PLAYERS = 100_000;
  const BASE_CYCLE_REWARD = 0.00001;
  const MIN_CYCLE_TIME = 3000; // 3 секунды
  const MAX_CYCLE_TIME = 10000; // 10 секунд
  const ENERGY_DRAIN_INTERVAL = 1800; // 1.8 секунды (100 энергии за 3 минуты = 1.8 секунды на 1 энергию)
  const MAX_RETRIES = 3;
  const MINING_POWER_MULTIPLIER = 1;

  // API методы
  const api = useMemo(() => ({
    baseUrl: process.env.NODE_ENV === 'production'
      ? '/api'  // В продакшене используем относительный путь
      : 'http://localhost:3002/api', // Для разработки

    async fetchWithHeaders(endpoint, options = {}) {
      const defaultHeaders = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Telegram-Data': window.Telegram?.WebApp?.initData || ''
      };

      try {
        const response = await fetch(`${this.baseUrl}${endpoint}`, {
          ...options,
          headers: {
            ...defaultHeaders,
            ...options.headers
          }
        });

        if (!response.ok) {
          const error = await response.json().catch(() => ({ message: 'Network error' }));
          throw new Error(error.message || 'Network response was not ok');
        }

        return response.json();
      } catch (error) {
        console.error(`Error in fetchWithHeaders for ${endpoint}:`, error);
        throw error;
      }
    },

    async initUser() {
      return this.fetchWithHeaders('/mining/init', {
        method: 'POST',
        body: JSON.stringify({
          telegramId: window.Telegram?.WebApp?.initDataUnsafe?.user?.id || TEST_USER.id,
          username: window.Telegram?.WebApp?.initDataUnsafe?.user?.username || TEST_USER.username
        })
      });
    },

    async getMiningHistory() {
      try {
        return await this.fetchWithHeaders('/mining/history');
      } catch (error) {
        console.error('Error in getMiningHistory:', error);
        return [];
      }
    },

    async getOnlineCount() {
      try {
        return await this.fetchWithHeaders('/mining/online-count');
      } catch (error) {
        console.error('Error in getOnlineCount:', error);
        return { count: 0 };
      }
    },

    async updateMining(reward, success) {
      return this.fetchWithHeaders('/mining/update', {
        method: 'POST',
        body: JSON.stringify({
          telegramId: window.Telegram?.WebApp?.initDataUnsafe?.user?.id || TEST_USER.id,
          username: window.Telegram?.WebApp?.initDataUnsafe?.user?.username || TEST_USER.username,
          reward,
          success
        })
      });
    },

    async buyEquipment() {
      return this.fetchWithHeaders('/mining/buy-equipment', {
        method: 'POST',
        body: JSON.stringify({
          telegramId: window.Telegram?.WebApp?.initDataUnsafe?.user?.id || TEST_USER.id,
          username: window.Telegram?.WebApp?.initDataUnsafe?.user?.username || TEST_USER.username
        })
      });
    },

    async upgradeEnergy() {
      const telegramId = window.Telegram?.WebApp?.initDataUnsafe?.user?.id || TEST_USER.id;
      return this.fetchWithHeaders('/mining/upgrade-energy', {
        method: 'POST',
        body: JSON.stringify({ telegramId })
      });
    },

    async getStats() {
      try {
        return await this.fetchWithHeaders('/mining/stats');
      } catch (error) {
        console.error('Error in getStats:', error);
        return { totalMiners: 0, onlineMiners: 0 };
      }
    },

    async updateEnergy(telegramId) {
      return this.fetchWithHeaders('/mining/update-energy', {
        method: 'POST',
        body: JSON.stringify({ telegramId })
      });
    },

    async updateOnlineStatus(telegramId, isOnline) {
      return this.fetchWithHeaders('/mining/update-status', {
        method: 'POST',
        body: JSON.stringify({ telegramId, isOnline })
      });
    },

    async getTopMiners() {
      try {
        const response = await this.fetchWithHeaders('/mining/leaderboard');
        return Array.isArray(response) ? response : [];
      } catch (error) {
        console.error('Error fetching leaderboard:', error);
        return [];
      }
    },

    async getUserRank() {
      try {
        const telegramId = window.Telegram?.WebApp?.initDataUnsafe?.user?.id || TEST_USER.id;
        const response = await this.fetchWithHeaders(`/mining/user-rank/${telegramId}`);
        return response || {
          rank: 0,
          totalMiners: 0,
          percentile: 0,
          stats: {
            balance: 0,
            totalMined: 0,
            equipment: 0,
            miningPower: 0,
            stars: 0
          }
        };
      } catch (error) {
        console.error('Error fetching user rank:', error);
        return {
          rank: 0,
          totalMiners: 0,
          percentile: 0,
          stats: {
            balance: 0,
            totalMined: 0,
            equipment: 0,
            miningPower: 0,
            stars: 0
          }
        };
      }
    }
  }), []);

  // Функция дя обработки ошибок API
  const handleApiError = useCallback(async (error, operation) => {
    console.error(`Error during ${operation}:`, error);

    const errorDetails = {
      message: error.message,
      operation,
      timestamp: new Date().toISOString()
    };

    setIsError(true);
    setErrorMessage(`Ошибка: ${error.message}`);

    try {
      await api.logError(errorDetails);
    } catch (logError) {
      console.error('Failed to log error:', logError);
    }

    if (retryCount < MAX_RETRIES) {
      setIsReconnecting(true);
      setRetryCount(prev => prev + 1);

      try {
        await new Promise(resolve => setTimeout(resolve, 2000 * retryCount));
        const userData = await api.initUser();
        if (userData) {
          setIsError(false);
          setErrorMessage('');
          setIsReconnecting(false);
          return true;
        }
      } catch (reconnectError) {
        console.error('Reconnection failed:', reconnectError);
      }
    }

    if (retryCount >= MAX_RETRIES) {
      setIsActive(false);
      if (window.Telegram?.WebApp) {
        window.Telegram.WebApp.showAlert('Не удалось восстановить соединение. Попробуйте позже.');
      }
    }
    return false;
  }, [api, retryCount, MAX_RETRIES]);

  // Эффекы
  useEffect(() => {
    if (isActive) {
      setMinersOnline(prev => prev + 1);
    }
    return () => {
      if (isActive) {
        setMinersOnline(prev => Math.max(0, prev - 1));
      }
    };
  }, [isActive]);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const userData = await api.initUser();
        setBalance(userData.balance || 0);
        setMiningPower(userData.miningPower || 0);
        setEnergy(userData.energy || 100);
        setMaxEnergy(userData.maxEnergy || 100);
        setEquipment(userData.equipment || 0);
        setTotalMined(userData.totalMined || 0);

        const history = await api.getMiningHistory();
        setMiningHistory(history || []);

        const { count } = await api.getOnlineCount();
        setMinersOnline(count || 0);
      } catch (error) {
        console.error('Error loading user data:', error);
        handleApiError(error, 'loading user data');
      }
    };

    loadUserData();
  }, [api, handleApiError]);

  // Эффект для майнинга
  useEffect(() => {
    let miningInterval;
    let energyDrainInterval;
    let isMounted = true;

    if (isActive) {
      const startTime = Date.now(); // Запоминаем время старта майнинга

      const startNewCycle = () => {
        const cycleTime = MIN_CYCLE_TIME + Math.random() * (MAX_CYCLE_TIME - MIN_CYCLE_TIME);

        miningInterval = setTimeout(async () => {
          if (!isMounted) return;

          try {
            const isSuccessful = Math.random() > 0.1;
            const baseReward = BASE_CYCLE_REWARD * miningPower;
            const variance = baseReward * 0.2;
            const reward = isSuccessful ? baseReward + (Math.random() * variance * 2 - variance) : 0;

            const response = await api.updateMining(reward, isSuccessful);

            if (response && isMounted) {
              setBalance(response.balance);
              setEnergy(response.energy);
              setTotalMined(response.totalMined);
              setCycleReward(reward);
              setLastMiningAttempt({ success: isSuccessful, reward });

              // Обновляем прогресс времени майнинга
              const miningDuration = Math.floor((Date.now() - startTime) / 1000);
              await api.updateTaskProgress('MINING_TIME', miningDuration);

              if (response.energy <= 0) {
                setIsActive(false);
                setCycleReward(0);
                if (window.Telegram?.WebApp) {
                  window.Telegram.WebApp.showAlert('Закончилась энергия');
                }
                return;
              }

              startNewCycle();
            }
          } catch (error) {
            console.error('Mining error:', error);
            if (isMounted) {
              if (error.message === 'Mining is already in progress on another device') {
                setIsActive(false);
                setCycleReward(0);
                if (window.Telegram?.WebApp) {
                  window.Telegram.WebApp.showAlert('Майнинг уже запущен на другом устройстве');
                }
              } else if (error.message === 'Not enough energy') {
                setIsActive(false);
                setCycleReward(0);
              }
            }
          }
        }, cycleTime);
      };

      startNewCycle();

      // Отдельный интервал для обновления заданий
      const taskUpdateInterval = setInterval(async () => {
        if (isMounted) {
          try {
            const miningDuration = Math.floor((Date.now() - startTime) / 1000);
            await api.updateTaskProgress('MINING_TIME', miningDuration);
            await api.updateTaskProgress('MINING_SESSIONS', miningSessionsCount);
            const tasks = await api.getTasks();
            setTasks(tasks);
          } catch (error) {
            console.error('Error updating tasks:', error);
          }
        }
      }, 10000);

      return () => {
        isMounted = false;
        if (miningInterval) clearTimeout(miningInterval);
        if (energyDrainInterval) clearInterval(energyDrainInterval);
        if (taskUpdateInterval) clearInterval(taskUpdateInterval);
        const telegramId = window.Telegram?.WebApp?.initDataUnsafe?.user?.id || '12345';
        api.stopMining(telegramId).catch(console.error);
      };
    }
  }, [isActive, miningPower, api]);

  // Эффект для восстановления энергии
  useEffect(() => {
    let energyRecoveryInterval;

    // Восстанавливаем энергию только когда майнг неактвен
    if (!isActive) {
      energyRecoveryInterval = setInterval(async () => {
        try {
          const telegramId = window.Telegram?.WebApp?.initDataUnsafe?.user?.id || '12345';
          const response = await api.updateEnergy(telegramId);

          if (response && response.energy !== undefined) {
            setEnergy(response.energy);
            setMaxEnergy(response.maxEnergy);
          }
        } catch (error) {
          console.error('Error updating energy:', error);
        }
      }, 5000);
    }

    return () => {
      if (energyRecoveryInterval) {
        clearInterval(energyRecoveryInterval);
      }
    };
  }, [isActive, api]);

  // Эффект для обновления онлайн статуса
  useEffect(() => {
    const updateOnlineStatus = async () => {
      try {
        const telegramId = window.Telegram?.WebApp?.initDataUnsafe?.user?.id || '12345';
        await api.updateOnlineStatus(telegramId, isActive);
      } catch (error) {
        console.error('Error updating online status:', error);
      }
    };

    updateOnlineStatus();
  }, [isActive, api]);

  // Эффект для обновления статистики
  useEffect(() => {
    const fetchStats = async () => {
      try {
        const stats = await api.getStats();
        console.log('Fetched stats:', stats);
        if (stats) {
          setMinersOnline(stats.onlineMiners || 0);
          setTotalMiners(stats.totalMiners || 0);
        }
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    fetchStats();
    const statsInterval = setInterval(fetchStats, 5000);

    return () => clearInterval(statsInterval);
  }, [api]);

  // Обновим эффект для истории
  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const history = await api.getMiningHistory();
        console.log('Fetched history:', history);
        setMiningHistory(history || []);
      } catch (error) {
        console.error('Error in getMiningHistory:', error);
      }
    };

    fetchHistory(); // Загружаем историю сразу
    const historyInterval = setInterval(fetchHistory, 10000); // Обновлем каждые 10 секунд

    return () => clearInterval(historyInterval);
  }, [api]);

  // Эффект для обновлния энергии
  useEffect(() => {
    const updateEnergyStatus = async () => {
      try {
        const telegramId = window.Telegram?.WebApp?.initDataUnsafe?.user?.id || '12345';
        const userData = await api.initUser();
        if (userData) {
          setEnergy(userData.energy);
          setMaxEnergy(userData.maxEnergy);
        }
      } catch (error) {
        console.error('Error updating energy status:', error);
      }
    };

    // Обновляем каждые 2 секунды
    const interval = setInterval(updateEnergyStatus, 2000);
    return () => clearInterval(interval);
  }, [api]);

  // Функции управления майннгом
  const startMining = useCallback(() => {
    setIsActive(true);
  }, []);

  const stopMining = useCallback(() => {
    setIsActive(false);
  }, []);

  const buyEquipment = useCallback(async () => {
    if (isPurchaseInProgress) return;

    try {
      setIsPurchaseInProgress(true);
      const userData = await api.buyEquipment();

      if (userData) {
        setEquipment(userData.equipment);
        setMiningPower(userData.miningPower);
        setBalance(userData.balance);

        // Обновляем прогресс задания по покупке оборудования
        await api.updateTaskProgress('BUY_EQUIPMENT', userData.equipment);
        const tasks = await api.getTasks();
        setTasks(tasks);
      }
    } catch (error) {
      console.error('Error buying equipment:', error);
    } finally {
      setIsPurchaseInProgress(false);
    }
  }, [api, isPurchaseInProgress]);

  const upgradeEnergy = useCallback(async () => {
    if (isUpgradeInProgress) return;

    try {
      setIsUpgradeInProgress(true);
      const userData = await api.upgradeEnergy();

      if (userData) {
        setEnergy(userData.energy);
        setMaxEnergy(userData.maxEnergy);
        setBalance(userData.balance);

        // Обновляем прогресс задания по улучшению энергии
        const upgradeCount = Math.floor((userData.maxEnergy - 100) / 100);
        await api.updateTaskProgress('UPGRADE_ENERGY', upgradeCount);
        const tasks = await api.getTasks();
        setTasks(tasks);

        // Показываем уведомление об успешном улучшении
        if (window.Telegram?.WebApp) {
          window.Telegram.WebApp.showAlert(`Энергия улучшена! Новый максимум: ${userData.maxEnergy}⚡`);
        }
      }
    } catch (error) {
      console.error('Error upgrading energy:', error);
      if (window.Telegram?.WebApp) {
        window.Telegram.WebApp.showAlert('Недостаточно средств для улучшения');
      }
    } finally {
      setIsUpgradeInProgress(false);
    }
  }, [api, isUpgradeInProgress]);

  // Контекст
  const value = {
    balance,
    miningPower,
    isActive,
    energy,
    maxEnergy,
    equipment,
    cycleReward,
    totalMined,
    minersOnline,
    totalMiners,
    miningHistory,
    TOTAL_SUPPLY,
    TOTAL_PLAYERS,
    setIsActive,
    setEquipment,
    setMiningPower,
    setMaxEnergy,
    setEnergy,
    buyEquipment,
    upgradeEnergy,
    startMining,
    stopMining,
    lastMiningAttempt,
    isError,
    errorMessage,
    isReconnecting,
    api,
    tasks,
    setTasks
  };

  return (
    <MiningContext.Provider value={value}>
      {children}
      {isReconnecting && (
        <div className="reconnecting-overlay">
          Восстановление соединения... Попытка {retryCount} из {MAX_RETRIES}
        </div>
      )}
    </MiningContext.Provider>
  );
}

export function useMining() {
  const context = useContext(MiningContext);
  if (!context) {
    throw new Error('useMining must be used within a MiningProvider');
  }
  return context;
}

export default MiningContext;