import React, { useState, useEffect } from 'react';
import { useMining } from '../context/MiningContext';
import '../styles/common.css';

function Tasks() {
  const { api, setEnergy, setMaxEnergy, equipment, maxEnergy } = useMining();
  const [referralCode, setReferralCode] = useState('');
  const [referrals, setReferrals] = useState({ active: 0, total: 0 });
  const [bonusEnergy, setBonusEnergy] = useState(0);
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Загружаем информацию о рефералах
        const telegramId = window.Telegram?.WebApp?.initDataUnsafe?.user?.id || '12345';
        const info = await api.getReferralInfo(telegramId);
        setReferralCode(info.referralCode);
        setReferrals({
          active: info.activeReferrals,
          total: info.totalReferrals
        });
        setBonusEnergy(info.activeReferrals * 20);

        // Загружаем задания
        const tasksData = await api.getTasks();

        // Обновляем прогресс для заданий с оборудованием и энергией
        const updatedTasks = tasksData.map(task => {
          if (task.type === 'BUY_EQUIPMENT') {
            return { ...task, progress: equipment };
          }
          if (task.type === 'UPGRADE_ENERGY') {
            return { ...task, progress: Math.floor((maxEnergy - 100) / 100) };
          }
          return task;
        });

        // Сортируем задания: незавершенные вверху, завершенные внизу
        const sortedTasks = updatedTasks.sort((a, b) => {
          if (a.completed === b.completed) return a.order - b.order;
          return a.completed ? 1 : -1;
        });

        setTasks(sortedTasks);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 10000);
    return () => clearInterval(interval);
  }, [api, equipment, maxEnergy]);

  const copyReferralLink = () => {
    const link = `https://t.me/MemBucksBot?start=${referralCode}`;
    navigator.clipboard.writeText(link);
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.showAlert('Ссылка скопирована!');
    }
  };

  const claimReward = async (taskId, reward) => {
    try {
      const result = await api.claimTaskReward(taskId);
      if (result.success) {
        // Обновляем энергию
        setEnergy(result.newEnergy);

        // Показываем уведомление
        try {
          if (window.Telegram?.WebApp?.showPopup) {
            window.Telegram.WebApp.showPopup({
              title: 'Награда получена',
              message: `Получено +${reward}⚡`,
              buttons: [{ type: 'ok' }]
            });
          } else {
            // Используем HapticFeedback для обратной связи
            window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success');

            // Можно также использовать MainButton для показа временного сообщения
            if (window.Telegram?.WebApp?.MainButton) {
              const originalText = window.Telegram.WebApp.MainButton.text;
              window.Telegram.WebApp.MainButton.setText(`Получено +${reward}⚡`);
              window.Telegram.WebApp.MainButton.show();

              setTimeout(() => {
                window.Telegram.WebApp.MainButton.hide();
                if (originalText) {
                  window.Telegram.WebApp.MainButton.setText(originalText);
                }
              }, 2000);
            }
          }
        } catch (notificationError) {
          console.log('Notification not supported:', notificationError);
        }

        // Обновляем список заданий
        const tasksData = await api.getTasks();
        const updatedTasks = tasksData.map(task => {
          if (task.type === 'BUY_EQUIPMENT') {
            return { ...task, progress: equipment };
          }
          if (task.type === 'UPGRADE_ENERGY') {
            return { ...task, progress: Math.floor((maxEnergy - 100) / 100) };
          }
          return task;
        }).sort((a, b) => {
          if (a.completed === b.completed) return a.order - b.order;
          return a.completed ? 1 : -1;
        });

        setTasks(updatedTasks);
      }
    } catch (error) {
      console.error('Error claiming reward:', error);
      // Используем более простой способ уведомления об ошибке
      try {
        window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('error');
      } catch (notificationError) {
        console.log('Haptic feedback not supported:', notificationError);
      }
    }
  };

  const formatProgress = (task) => {
    switch (task.type) {
      case 'TOTAL_MINED':
        return `${task.progress.toFixed(6)} / ${task.requirement.toFixed(6)}`;
      case 'MINING_TIME':
        // Конвертируем секунды в минуты и секунды, учитывая только положительные значения
        const totalSeconds = Math.max(0, task.progress || 0);
        const currentMinutes = Math.floor(totalSeconds / 60);
        const currentSeconds = Math.floor(totalSeconds % 60);
        const requiredMinutes = Math.floor(task.requirement / 60);
        const requiredSeconds = Math.floor(task.requirement % 60);
        return `${currentMinutes}:${currentSeconds.toString().padStart(2, '0')} / ${requiredMinutes}:${requiredSeconds.toString().padStart(2, '0')}`;
      default:
        return `${Math.floor(task.progress || 0)} / ${task.requirement}`;
    }
  };

  const getProgressPercentage = (task) => {
    if (task.type === 'MINING_TIME') {
      const progress = Math.max(0, task.progress || 0);
      return Math.min(100, (progress / task.requirement) * 100);
    }
    return Math.min(100, ((task.progress || 0) / task.requirement) * 100);
  };

  return (
    <div className="page">
      {/* Реферальная система */}
      <div className="content-block">
        <h3 className="section-header">Реферальная программа</h3>
        <div className="info-row">
          <span className="label">Ваш код приглашения</span>
          <button className="copy-button" onClick={copyReferralLink}>
            {referralCode} 📋
          </button>
        </div>
        <div className="info-row">
          <span className="label">Активные рефералы</span>
          <span className="value highlight">{referrals.active}</span>
        </div>
        <div className="info-row">
          <span className="label">Бонус к энергии</span>
          <span className="value">+{bonusEnergy}</span>
        </div>
        <div className="referral-info">
          <p>За каждого активного реферала вы получаете:</p>
          <ul>
            <li>+20 к максимальной энергии</li>
            <li>Реферал должен купить минимум 1 оборудование</li>
          </ul>
        </div>
      </div>

      {/* Список заданий */}
      <div className="content-block">
        <h3 className="section-header">Задания</h3>
        <div className="tasks-list">
          {tasks.map((task) => (
            <div key={task._id} className={`task-item ${task.completed ? 'completed' : ''}`}>
              <div className="task-header">
                <span className="task-title">{task.title}</span>
                <span className="task-reward">+{task.reward}⚡</span>
              </div>
              <div className="task-description">{task.description}</div>
              <div className="task-progress">
                <div className="progress-bar-container">
                  <div
                    className="progress-bar"
                    style={{ width: `${getProgressPercentage(task)}%` }}
                  />
                </div>
                <span className="progress-text">
                  {formatProgress(task)}
                </span>
              </div>
              {task.type === 'SUBSCRIBE_CHANNEL' && !task.completed && (
                <button
                  className="claim-button"
                  onClick={() => window.Telegram?.WebApp?.openTelegramLink('https://t.me/MEMBUCKS')}
                >
                  Подписаться
                </button>
              )}
              {task.completed && !task.rewardClaimed && (
                <button
                  className="claim-button"
                  onClick={() => claimReward(task._id, task.reward)}
                >
                  Получить +{task.reward}⚡
                </button>
              )}
              {task.rewardClaimed && (
                <span className="reward-claimed">✓ Награда получена</span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Tasks;